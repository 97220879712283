<template>
    <div>
        <div class="artsys-pagination">
            <a :class="'pill back-link me-2 ' + (pageNumber <= 1 ? 'faded' : '')" href="javascript:void(0)" @click="refreshData(pageNumber - 1)">{{ t["previousPage"] }}</a>
            <a :class="'pill back-link ' + (pageNumber >= pageCount ? 'faded' : '')" href="javascript:void(0)" @click="refreshData(parseInt(pageNumber) + 1)">{{ t["nextPage"] }}</a>
        </div>
        <div class="artsys-artworks-list">
            <div v-for="artwork in artworks" v-bind:key="artwork.id">
                <div class="artsys-artwork-tile-img">
                    <a :href="artistUrl + '?artist=' + artwork.id_user + '&artwork=' + artwork.id">
                        <img :src="artwork.images[0]" :alt="artwork.title">
                    </a>
                </div>
                <h4 class="artsys-artwork-tile-name">
                    <a :href="artistUrl + '?artist=' + artwork.id_user + '&artwork=' + artwork.id">
                        {{ artwork.title }}

                        <span v-if="artwork.display_status == 'Sold'" class="artwork-sold">
                            ({{ t["sold"] }})
                        </span>
                    </a>
                </h4>
                <div>
                    {{ t["by"] }} {{ artwork.artist_name }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                artworks : [],
                artsysUrl : "",
                artistUrl : "",
                apiUrl : null,
                pageNumber: 1,
                pageCount : 2,
                t : [],
            }
        },
        methods : {
            setData(lang, artistUrl, artsysUrl, apiUrl, translations) {
                this.lang = lang;
                this.artsysUrl = artsysUrl;
                this.artistUrl = artistUrl;
                this.apiUrl = apiUrl;
                this.t = translations;
                let page = location.hash.replace("#", "");

                if (page == "" || isNaN(page)) {
                    page = 1;
                }

                this.pageCount = page;
                this.refreshData(page);
            },
            refreshData(pageNumber) {
                if (pageNumber >= 1 && pageNumber <= this.pageCount) {
                    this.pageNumber = pageNumber;
                    location.hash = pageNumber;

                    fetch(this.apiUrl + "artworks/p/" + this.pageNumber, {
                        method: "GET",
                        credentials: 'omit',
                        mode: 'cors'
                    })
                    .then(response => response.json())
                    .then(data => {
                        this.pageCount = Math.ceil(data.total/data.pageSize);
                        this.artworks = data.artworks;
                    });
                }
            },
        }
    }
</script>
<style scoped>
    .artsys-pagination {
        text-align: center;
    }

    .faded {
        opacity: 0.5;
    }
</style>