const t = {
    "bio" : "Biography",
    "discover" : "Discover more about the artist",
    "website" : "Website",
    "process" : "Artistic approach",
    "showroom" : "Virtual showroom",
    "discoverOtherYears" : "Discover all artists from other years",
    "by" : "by",
    "back" : "Back to artists",
    "back-to-artist" : "Back",
    "materials" : "Materials",
    "serialNumber" : "Serial number",
    "dimensions" : "Dimensions",
    "height" : "Height",
    "depth" : "Depth",
    "width" : "Width",
    "duration" : "Duration",
    "video" : "Video",
    "creation_year" : "Creation year",
    "medium" : "Medium",
    "sold" : "Sold",
    "contact_form_text" : "For information on the artwork or to buy it, communicate with the artist with the following form.",
    "send" : "Send",
    "name" : "Name",
    "email" : "Email",
    "message" : "Message",
    "emailSent" : "Email send!",
    "artworks" : "Artworks",
    "subvention" : "Subvention",
    "callForProject" : "Call for project",
    "exposition" : "Exposition",
    "workshop" : "Workshop",
    "conference" : "Conference",
    "all" : "All",
    "filterByType" : "Filter by type",
    "close": "Close",
    "today": "Today",
    "showList" : "Show list",
    "hideList" : "Hide list",
    "date" : "Date",
    "from" : "From",
    "to" : "to",
    "invalidImage" : "Image format is invalid",
    "tryMinWidth" : "Try again with an image whose minimum width is of",
    "tryMaxWidth" : "and whose minimum height is of",
    "EventListTitle" : "Summary of events",
    "findOutMore" : "Find out more",
    "previousPage" : "Previous page",
    "nextPage" : "Next page",
}

export default t;